/**
 * This handles menus and drop downs
 */

export default {
    // Handles timeouts
    hover: 0,

    // Handles all modals
    items: {},

    add ( modals ) {
        return (this.items = modals);
    },

    toggle ( key, callback ) {
        clearTimeout(this.hover);
        this.hover = setTimeout(() => {
            for ( let module in this.items ) {
                if ( this.items.hasOwnProperty(module)) {
                    this.items[module].active = ( module === key ? !this.items[module].active  : false );
                }
            }
            if ( typeof callback !== "undefined") {
                callback();
            }
        }, 150, this );

    },
    show ( key ) {
        for ( let module in this.items ) {
            if ( this.items.hasOwnProperty(module)) {
                this.items[module].active = ( module === key );
            }
        }
    },
    hide ( callback ) {
        for ( let module in this.items ) {
            if ( this.items.hasOwnProperty(module)) {
                this.items[module].active = false;
            }
        }
        if ( typeof callback !== "undefined") {
            callback();
        }
    },
    visible ( key ) {
        if ( typeof key !== "undefined") {
            return this.items[key].active;
        }
        for ( let module in this.items ) {
            if ( this.items.hasOwnProperty(module) && this.items[module].active === true ) {
                return true;
            }
        }
        return false;
    },

    // Show on mouse over
    enter ( key, callback ) {
        clearTimeout(this.hover);
        this.hover = setTimeout(function (self) {
            self.show(key);
            if ( typeof callback !== "undefined") {
                callback();
            }
        }, 150, this );
    },

    // Hide on mouse leave
    leave ( callback ) {
        clearTimeout(this.hover);
        this.hover = setTimeout(function(self) {
            self.hide();
            if ( typeof callback !== "undefined") {
                callback();
            }
        }, 150, this );
    }
};



