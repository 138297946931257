/**
 * This handles include html components
 */

export default (vue) => {

    //console.log(vue.get(vue.exp));

    fetch(vue.exp)
        .then((response) => {
            return response.text();
        })
        .then((html) => {
            vue.el.innerHTML = html;

            setTimeout(() => {
                app.mount();
            }, 50 );

        })
        .catch();

};



