
export default (vue) => {

    let tabs = vue.el.querySelectorAll('[data-tab="' + vue.exp + '"]');
    tabs = Array.prototype.slice.call ( tabs );

    let panes = vue.el.querySelectorAll('[data-pane="' + vue.exp + '"]');
    panes = Array.prototype.slice.call ( panes );

    tabs.forEach((item, index) => {
        item.addEventListener( window.touchHandler, (e) => {
            e.preventDefault();
            tabs.forEach((tab, j) => {
                tab.dataset.active = j === index ? 'true' : 'false';
                panes[j].dataset.active = j === index ? 'true' : 'false';
            });
            return false;
        });
    });

};