/**
 * @ngdoc directive
 * @name vue.directive:vUntil
 */

export default (vue) => {
    // Set the date we're counting down to
    let target = new Date(vue.exp).getTime();

    // Update the count down every 1 second
    var x = setInterval(() => {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = target - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        days = parseInt(days);
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        hours = parseInt(hours);
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        minutes = parseInt(minutes);
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        seconds = parseInt(seconds);


        // Display the result in the element with id="demo"

        vue.el.innerHTML = "Pleacă în " +
            ( days ? days + " zi " : '') +
            ( hours ? hours + "h " : '') +
            ( parseInt(minutes) === 1 ? "un minut" : ( minutes + ' ' + (minutes >= 20 ? ' de ' : '') + 'minute')) + ' ' +
            ( minutes ? ' si ' : '') +
            ( parseInt(seconds) === 1 ? "o sec" : ( seconds + ' ' + (seconds >= 20 ? ' de ' : '') + 'sec'));

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            vue.el.innerHTML = "";
        }

    }, 1000);

};