
export default (vue) => {
    vue.el.addEventListener(touchHandler,() => {
        if ( vue.exp.length ) {
            vue.ctx.scope.embed.show = true;
            vue.ctx.scope.embed.url = vue.exp;
        } else {
            vue.ctx.scope.embed.show = false;
            vue.ctx.scope.embed.url = null;
        }
    }, { passive: true });
};