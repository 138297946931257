import {createApp} from 'petite-vue';
import complete from "./directives/complete";
import editor from "./directives/editor";
import aside from "./directives/aside";
import lazy from "./directives/lazy";
import search from "./modules/search";
import tabs from "./directives/tabs";
import until from "./directives/until";
import include from "./directives/include";
import skip from "./directives/skip";
import embed from "./directives/embed";
import hour from "./directives/hour";
import modal from "./modules/modal";
import analytics from "./directives/analytics";
import behave from "./services/behave";
import adsense from "./directives/adsense";

window.touchHandler = ('ontouchstart' in document.documentElement ? "touchstart" : "click");

window.app = createApp({
    behave: false,
    adsense: false,
    analytics: false,
    service: {
        ready: false,
    },
    // Used for autocomplete
    complete: {
        departure: false,
        destination: false,
        train: false,
    },
    modal: {
        menu: new modal(),
        head: new modal(),
        embed: new modal(),
    },
    embed: {
        show: false,
        url: null,
    },
    search: {
        location: new search('/search/complete.json'),
        departure: new search('/search/complete.json'),
        destination: new search('/search/complete.json?d='),
        train: new search('/search/train.json'),
        operator: new search('/search/operator.json'),
    },
    mounted() {
        behave.event(() => {
            this.behave = true;
        });
    }
});

app.directive('complete', complete);
app.directive('editor', editor);
app.directive('aside', aside);
app.directive('include', include);
app.directive('lazy', lazy);
app.directive('tabs', tabs);
app.directive('until', until);
app.directive('skip', skip);
app.directive('embed', embed);
app.directive('hour', hour);
app.directive('adsense', adsense);
app.directive('analytics', analytics);
app.mount();
