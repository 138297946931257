
export default class search {

    constructor(source) {
        this.source = source;
        this.results = [];
        this.focus = false;
        this.name = null;
        this.id = null;
    }

    reset() {
        this.results = [];
        this.focus = false;
        this.name = null;
        this.id = null;
    }

    choose(item) {
        this.results = [];
        this.focus = false;
        this.name = item.hasOwnProperty('text') ? item.text : item.name;
        this.id = item.id;
    }

}
