/**
 * This handles menus and drop downs
 */

export default {
    event: (callback) => {

        let interacted = false;

        let available = {
            'orientationchange': window,
            'resize': window,
            'scroll': document,
            'mousemove': document,
            'keydown': document,
            'click': document,
            'touchstart': document,
        };

        let interacts = (event) => {
            // console.log(event.type);
            if ( interacted === false ) {
                for (const [key, value] of Object.entries(available)) {
                    value.removeEventListener(key, interacts);
                }
                if ( typeof callback !== "undefined") {
                    callback(event);
                }
                interacted = true;
            }
        };

        for (const [key, value] of Object.entries(available)) {
            value.addEventListener(key, interacts, { passive: true });
        }

    }
};



