import tickle from "../modules/tickle";

/**
 * This will close modals
 */

export default (vue) => {
    vue.el.addEventListener(touchHandler, () => {
        tickle.hide(() => {
            vue.ctx.scope.modals[vue.exp] = false;
        });
    }, { passive: true });
};



