const delay = function() {
    let args = Array.prototype.slice.call(arguments);
    return new Promise(function(resolve) {
        setTimeout.apply(null, [resolve].concat(args));
    });
};

const loadElement = (tag, properties, attributes ) => {
    return new Promise((resolve, reject) => {
        const element = document.createElement(tag);
        Object.assign(element, properties);
        Object.assign(element, attributes);

        element.addEventListener('load', () => {
            delay(300).then(resolve);
        }, { passive: true });
        element.addEventListener('error', () => {
            delay(300).then(reject);
        }, { passive: true });

        document.getElementsByTagName('head')[0].appendChild(element);
    });
};

export default {
    // script: (link, attributes) => {
    //     attributes = attributes || {};
    //     const properties = { async: true, src: link };
    //     Object.assign(properties, attributes);
    //     loadElement('script', properties );
    // },
    script: (link, attributes) => loadElement('script', { async: true, src: link }, attributes || {}),
    style:  link => loadElement('link', { rel: 'stylesheet', href: link }),
};
