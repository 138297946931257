export default (vue) => {

    let typing = 0;
    let source = null;

    vue.el.addEventListener('focus', () => {
        vue.ctx.scope.search[vue.exp].focus = true;
    }, { passive: true });
    vue.el.addEventListener('blur', () => {
        vue.ctx.scope.search[vue.exp].focus = false;
    }, { passive: true });

    vue.el.addEventListener('keyup', () => {
        source = vue.el.dataset.source + (vue.el.dataset.source.includes("?") ? '&' : '?') + 'q=';
        if (typing) {
            clearTimeout(typing);
        }
        if ( 2 <= vue.el.value.length) {
            typing = window.setTimeout(() => {
                //console.log("http get: " + source + vue.el.value)
                fetch(source + vue.el.value).then((responses) => {
                    return responses.json();
                }).then((json) => {
                    vue.ctx.scope.search[vue.exp].results = json.results;
                });
            }, 200);
        } else {
            vue.ctx.scope.search[vue.exp].results = [];
        }
    }, { passive: true });

};