/**
 * @ngdoc directive
 * @name vue.directive:vEditor
 * This handles includes for html components
 */

import extend from "../modules/extend";

export default (vue) => extend(vue, (vue) => {

    // console.log("editor mount");

    let html = vue.get('modal.' + vue.exp + '.html');

    if ( html ) {
       //  console.log("editor html");
        vue.el.innerHTML = html;
        setTimeout(() => {
            app.mount();
        }, 50 );
    } else {
        // console.log("editor empty");
    }

    // IF the element is unmounted
    return () => {
        vue.set('editor', {});
    }
});