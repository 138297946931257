/**
 * @ngdoc directive
 * @name vue.directive:vAnalytics
 * This handles includes for analytics script
 */

export default (vue) => {
    vue.effect(() => {
        if ( vue.ctx.scope.behave && vue.ctx.scope.analytics === false ) {
            vue.ctx.scope.analytics = true;

            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            window.ga('create', 'UA-9478150-4', 'auto');
            window.ga('send', 'pageview');

        }
    });

};