/**
 * @ngdoc directive
 * @name vue.directive:vAside
 * This handles includes for html components
 */

import extend from "../modules/extend";

export default (vue) => extend(vue, (vue) => {
    let source = {};

    vue.el.addEventListener ( window.touchHandler, () => {
        source = {
            html: null,
            json: null,
        };
        // Close the modal if visible
        if ( vue.get('modal.' + vue.exp + '.show') === true ) {
            vue.set('modal.' + vue.exp + '.view', false );
            setTimeout(() => {
                vue.set('modal.' + vue.exp + '.show', false );
                vue.set('modal.' + vue.exp + '.html', null );
                vue.set('editor', {});
            }, 250 );
            return false;
        }
        // Load html view
        if ( vue.el.dataset.hasOwnProperty('html')) {
            source.html = vue.el.dataset.html;
            // console.log("Aside html + " + vue.el.dataset.html );
        }
        // Load json data
        if ( vue.el.dataset.hasOwnProperty('json')) {
            source.json = vue.el.dataset.json;
            // console.log("Aside json + " + vue.el.dataset.json );
        }

        switch (true) {
            case source.json != null && source.html != null:
               // console.log("Aside has json + html");
                Promise.all([
                    fetch(source.json),
                    fetch(source.html),
                ]).then((responses) => {
                    return Promise.all([
                        responses[0].json(),
                        responses[1].text(),
                    ]);
                }).then((responses) => {
                    vue.set('editor', responses[0].payload );
                    vue.set('modal.' + vue.exp + '.html' , responses[1]);
                    vue.set('modal.' + vue.exp + '.show', true );
                    vue.set('modal.' + vue.exp + '.view', true );
                }).catch();
                break;
            case source.json == null && source.html != null:
                console.log("Aside HTML");
                fetch(source.html)
                    .then((r) => {
                        return r.text();
                    })
                    .then((text) => {
                        vue.set('modal.' + vue.exp + '.html' , text);
                        vue.set('modal.' + vue.exp + '.show', true );
                        vue.set('modal.' + vue.exp + '.view', true );
                    })
                    .catch();
                break;
            case source.json != null && source.html == null:
                // console.log("Aside JSON");
                fetch(source.json)
                    .then((r) => {
                        return r.json();
                    })
                    .then((responses) => {
                        vue.set('editor', responses[0].payload );
                        vue.set('modal.' + vue.exp + '.show', true );
                        vue.set('modal.' + vue.exp + '.view', true );
                    })
                    .catch();

                break;
            default:
                vue.set('modal.' + vue.exp + '.show', true );
                break;
        }

    }, { passive: true });
});