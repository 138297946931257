/**
 * @ngdoc directive
 * @name vue.directive:vHour
 */

export default (vue) => {
    // Update the count down every 1 second
    setInterval(() => {

        var currentTime = new Date()
        var hours = currentTime.getHours();
        var minutes = currentTime.getMinutes();
        var sec = currentTime.getSeconds();
        if (hours < 10){
            hours = "0" + hours
        }
        if (minutes < 10){
            minutes = "0" + minutes
        }
        if (sec < 10){
            sec = "0" + sec
        }
        var t_str = hours + ":" + minutes + ":" + sec + " ";
        if(hours > 11){
            t_str += "PM";
        } else {
            t_str += "AM";
        }
        vue.el.innerHTML = t_str;
    }, 1000);

};