export default () => {

    if ("IntersectionObserver" in window ) {
        let items = document.querySelectorAll("[data-src]");

        let images = Array.prototype.slice.call ( items );

        let lazyImageObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.intersectionRatio > 0) {
                    let lazyImage = entry.target;
                    if ( entry.target.tagName.toLowerCase() === 'img') {
                        if (lazyImage.dataset.hasOwnProperty('src')) {
                            lazyImage.src = lazyImage.dataset.src;
                        }
                    }
                    if ( entry.target.tagName.toLowerCase() === 'svg') {
                        if ( lazyImage.dataset.hasOwnProperty('src')) {
                            lazyImage.style.backgroundSize = "cover";
                            lazyImage.style.backgroundImage = "url('" + lazyImage.dataset.src + "')";

                        }
                    }
                    delete lazyImage.dataset.src;

                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        images.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });

        return true;
    }

};