import asyncron from "../services/asyncron";

/**
 * @ngdoc directive
 * @name vue.directive:vAdsense
 * This handles includes for adsense scripts
 */


export default (vue) => {

    vue.effect(() => {
        if ( vue.ctx.scope.behave ) {
            if ( vue.ctx.scope.adsense === false ) {
                vue.ctx.scope.adsense = true;
                asyncron.script('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2217555297680827', {crossOrigin: "anonymous"}).then(() => {
                    // console.log("T");
                    (adsbygoogle = window.adsbygoogle || []).push({});
                });
            } else {
                // console.log("X");
                // (adsbygoogle = window.adsbygoogle || []).push({});
            }
        }
    });

};